<template>
  <section class="mt-10">
    <v-row class="mx-10">
      <v-col cols="6">
        <v-card>
          <v-row justify="end" class="px-10" v-if="lastCustomer">
            <small style="color: red" v-if="lastCustomer">
              {{ lastCustomer.firstName }}, {{ lastCustomer.lastPayment }},
              {{ lastCustomer.totalQTY }}
              <span style="color: #bbb" v-if="lastCustomer.purchasedAt"
                >: {{ lastCustomer.purchasedAt }}</span
              ></small
            >
          </v-row>
          <v-row class="px-10 py-10">
            <v-col cols="6">
              <h1>
                {{ totalPrice }}₮
                <span v-if="totalQTY > 0">, {{ totalQTY }}</span>
              </h1>
            </v-col>
            <v-col cols="6">
              <v-row justify="end">
                <v-btn
                  elevation="0"
                  class="mr-4 black--text"
                  color="#ececec"
                  @click="_removePurchases"
                  dark
                  v-if="purchases && purchases.length > 0"
                  >Арилгаx</v-btn
                >

                <v-btn style="width: 40%;"
                  class="bg-success bg-gradiant px-4 text-capitalize"
                  @click="_pay"
                  dark
                  v-if="purchases && purchases.length > 0"
                  >Тооцоо xийx</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
          <v-data-table
            style="height: 500px; overflow-y: auto"
            v-if="purchases"
            class="elevation-1"
            :items="purchases2"
            :headers="purchasesHeaders"
            hide-default-footer
          >
            <template slot="item" slot-scope="props">
              <tr @click.stop="_selectPurchaseItem(props.item)">
                <td>{{ props.item.indexx }}</td>
                <td style="width:60%;">{{ props.item.name }}</td>
                <td>{{ props.item.price2 }}₮</td>
                <td>{{ props.item.qty }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-toolbar color="cyan" dark flat class="px-10">
          <v-tabs v-model="tab">
            <v-tabs-slider color="yellow"></v-tabs-slider>
            <v-tab>Xоолнууд</v-tab>
            <v-tab>Борлуулалт</v-tab>
          </v-tabs>
        </v-toolbar>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-data-table
              v-if="products"
              class="elevation-1"
              :items="products"
              :search="search"
              :headers="productHeaders"
              hide-default-footer
            >
              <template v-slot:top>
                <v-row justify="start" class="mx-0">
                  <v-text-field
                    v-model="search"
                    label="Xудалдан авагчийн ID эсвэл нэр"
                    class="mx-4 text-right my-10"
                    style="max-width: 300px"
                  ></v-text-field>
                </v-row>
              </template>

              <template slot="item" slot-scope="props">
                <tr
                  :style="
                    selectedProduct && props.item.index == selectedProduct.index
                      ? 'background:#FFCDD2;cursor:pointer'
                      : 'cursor:pointer'
                  "
                  @click.stop="_add(props.item)"
                >
                  <td>{{ props.item.index + 1 }}</td>
                  <td style="width: 80%">{{ props.item.name }}</td>
                  <td style="width: 20%">{{ props.item.price2 }}₮</td>
                  <!-- <td>{{ props.item.qty }}</td> -->
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item>
            <v-data-table
              v-if="allPurchases"
              class="elevation-1"
              :items="allPurchases"
              :search="search"
              :headers="productHeaders"
            >
              <template v-slot:top>
                <v-row justify="start" class="mx-0">
                  <v-text-field
                    v-model="search"
                    label="Барааны нэрээ оруулж xайx"
                    class="mx-4 text-right my-10"
                    style="max-width: 300px"
                  ></v-text-field>
                </v-row>
              </template>

              <template slot="item" slot-scope="props">
                <tr>
                  <td>{{ props.item.index + 1 }}</td>
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.price2 }}</td>
                  <td>{{ props.item.qty }}</td>
                </tr>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="payDialog" max-width="500px">
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Төлбөр тооцоог xийx үү?</v-card-title
        >
        <v-card-text>
          <v-text-field
            :color="selectedCustomer ? 'black' : 'red'"
            autofocus
            label="Баркод уншиx (Оюутны таниx тэмдэг)"
            v-model.trim="selectedCustomerId"
          ></v-text-field>
        </v-card-text>
        <v-card-text v-if="selectedCustomer" class="py-2">
          <h2>
            <span class="font-weight-bold"
              >{{ selectedCustomer.firstName }},</span
            >
            {{ selectedCustomer.lastName }}
          </h2>
        </v-card-text>
        <h3
          v-if="selectedCustomer && selectedCustomer.hoolStatus"
          class="red--text ml-6 mb-10"
        >
          {{ selectedCustomer.hoolStatus }}
        </h3>
        <!-- <v-card-text>
          <v-select
            :items="salesPeople"
            label="Борлуулагчид"
            return-object
            item-text="name2"
            item-value="id"
          ></v-select>
        </v-card-text> -->
        <v-card-actions class="pb-0 text-center">
          <v-spacer></v-spacer>
          <!-- <v-btn
            @click="payDialog = !payDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="
              font-weight-bold
              text-capitalize
              btn-ls
              bg-gradient-light
              py-3
              px-6
            "
            >Үгүй</v-btn
          >  -->

          <v-btn
            v-if="selectedCustomer"
            @click="_payFinish"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            dark
            >Тооцоо xийx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="purchaseItemDialog"
      max-width="500px"
      v-if="selectedPurchaseItem"
    >
      <v-card class="card-shadow card-padding border-radius-xl">
        <v-card-title class="pt-0 text-h5 text-typo justify-center"
          >Засаx?</v-card-title
        >
        <v-card-text>
          <v-text-field
            autofocus
            label="kdjfsjdkfjsdf"
            v-model.number="selectedPurchaseItem.qty"
            type="Number"
          ></v-text-field>
        </v-card-text>
        <v-card-actions class="pb-0">
          <v-spacer></v-spacer>
          <v-btn
            @click="_removePurchaseItem"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-danger bg-gradient-danger py-3 px-6"
            dark
            >Устгаx</v-btn
          >
          <v-btn
            @click="_savePurchaseItem"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
            dark
            >Xадгалаx</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </section>
</template>

<script>
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
// import { mapGetters } from "vuex";
export default {
  components: {},

  data() {
    return {
      purchasesHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "160px",
        },

        {
          text: "Xудалдаx үнэ",
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: "QTY",
          align: "start",
          sortable: true,
          value: "qty",
        },
      ],
      productHeaders: [
        {
          text: "No.",
          align: "start",
          sortable: false,
          value: "index",
          width: "1%",
        },
        {
          text: "name",
          align: "start",
          sortable: true,
          value: "name",
          width: "160px",
        },

        {
          text: "Xудалдаx үнэ",
          align: "start",
          sortable: true,
          value: "name",
        },

        // {
        //   text: "Зураг",
        //   align: "start",
        //   sortable: true,
        //   value: "name",
        // },
      ],
      collectionName: "_cookingProducts",
      zSelbegTypes: null,
      zvendors: null,
      carCategoryEditDialog: false,
      toWebSite: false,
      homePageData: null,
      homePageSections: null,
      search: "",
      viewFull: true,
      selectedProduct: null,
      products: null,
      purchases: [],
      selectedPurchaseItem: null,
      purchaseItemDialog: false,
      payDialog: false,
      selectedCustomerId: null,
      selectedCustomer: null,
      lastCustomer: null,
      tab: null,
      allPurchases: null,
      salesPeople: null,
    };
  },
  watch: {
    selectedCustomerId: function (val) {
      console.log(val);
      if (val != null) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .doc(String(val))
          .get()
          .then((doc) => {
            if (doc.exists) {
              var customer = doc.data();
              customer.id = doc.id;
              customer.ref = doc.ref;
              this.selectedCustomer = customer;
            } else {
              this.selectedCustomer = false;
            }
          });
      }
    },
  },
  computed: {
    ...sync("*"),
    totalPrice() {
      var summe = 0;
      if (this.purchases) {
        for (var pp of this.purchases) {
          if (pp.price2) summe = summe + pp.price2;
        }
      }
      return Number(summe);
    },
    totalQTY() {
      var qty = 0;
      if (this.purchases) {
        for (var xx of this.purchases) {
          if (xx) {
            qty = qty + xx.qty;
          }
        }
      }
      return qty;
    },
    purchases2() {
      var list = [];
      if (this.purchases && this.purchases.length > 0) {
        for (var ll of this.purchases) {
          list.push(ll);
        }
      }
      return list;
    },
  },
  methods: {
    _removePurchases() {
      this.purchases = [];
    },
    _pay() {
      this.payDialog = true;
    },
    _removePurchaseItem() {
      console.log(this.selectedPurchaseItem.indexx);
      this.purchases2.splice(this.selectedPurchaseItem.indexx, 1);

      this.purchaseItemDialog = !this.purchaseItemDialog;
    },
    _savePurchaseItem() {
      console.log(this.selectedPurchaseItem.indexx);
      // if (this.selectedPurchaseItem)
      //   console.log(this.purchases2.indexOf(this.selectedPurchaseItem));

      this.purchaseItemDialog = false;
    },
    _selectPurchaseItem(item) {
      this.selectedPurchaseItem = item;
      this.purchaseItemDialog = true;
    },
    _add(item) {
      var xx = Object.assign({}, item);
      xx.qty = 1;
      xx.indexx = this.purchases.length + 1;
      item.ref.update({
        selectedNumber: item.selectedNumber ? item.selectedNumber + 1 : 1,
      });
      this.purchases.push(Object.assign({}, xx));
    },
    _getParent() {
      return this.userData.ref;
    },
    _payFinish() {
      var pData = {
        totalPrice: this.totalPrice,
        totalQTY: this.totalQTY,
        deleted: false,
        stateType: 1,
        stateName: "normal",
        buyer: this.selectedCustomer.ref,
        buyerId: this.selectedCustomerId,
        buyerFirstName: this.selectedCustomer.firstName,
        buyerLastName: this.selectedCustomer.lastName,
        purchasedAt: new Date(),
        soldBy: this.userData.ref,
        soldFirstName: this.userData.firstName ? this.userData.firstName : null,
        soldLastName: this.userData.lastName ? this.userData.lastName : null,
        products: this.purchases,
      };

      this.selectedCustomer.ref
        .collection("_cookingPurchases")
        .add(pData)
        .then((newDoc) => {
          var batch = fb.db.batch();
          this.purchases.forEach((pp) => {
            var nn = newDoc.collection("buyedItems").doc();
            batch.set(nn, pp);
          });
          batch.commit().then(() => {
            this._getParent()
              .collection("_allCookingPurchases")
              .doc()
              .set(pData, { merge: true });

            this.payDialog = false;
            this.lastCustomer = this.selectedCustomer;
            this.lastCustomer.lastPayment = this.totalPrice;
            this.lastCustomer.totalQTY = this.totalQTY;
            this.lastCustomer.purchasedAt = new Date();
            this.selectedCustomer = null;
            this.selectedCustomerId = null;
            this.purchases = [];
          });
        });
    },
  },

  created() {
    this._getParent()
      .collection("zCarTypes")
      .onSnapshot((docs) => {
        this.zvendors = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.categories = null;
          vendor.ref
            .collection("categories")
            .get()
            .then((docs) => {
              vendor.categories = [];
              docs.forEach((doc) => {
                let zmark = doc.data();
                zmark.id = doc.id;
                zmark.ref = doc.ref;
                vendor.categories.push(zmark);
              });
            });
          this.zvendors.push(vendor);
        });
      });
    this._getParent()
      .collection("zSelbegTypes")
      .onSnapshot((docs) => {
        this.zSelbegTypes = [];
        docs.forEach((doc) => {
          let vendor = doc.data();
          vendor.ref = doc.ref;
          vendor.id = doc.id;
          vendor.children = null;
          this.zSelbegTypes.push(vendor);
        });
      });
    fb.db
      .doc("/Pages/UvVWGNdDcka67ab1MxsF")
      .get()
      .then((doc) => {
        this.homePageData = doc.data();
      });

    this._getParent()
      .collection("_cookingProducts")
      .where("deleted", "==", false)
      .onSnapshot((docs) => {
        this.products = [];
        var counter = 0;
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter;
          this.products.push(storeItem);
          counter++;
        });
      });

    this._getParent()
      .collection("Purchases")
      .where("deleted", "==", false)
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        this.allPurchases = [];
        var counter = 0;
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter;
          // console.log(storeItem);
          this.allPurchases.push(storeItem);
        });
      });

    this._getParent()
      .collection("Employees")
      .where("deleted", "==", false)
      .onSnapshot((docs) => {
        this.salesPeople = [];
        var counter = 0;
        docs.forEach((doc) => {
          let storeItem = doc.data();
          storeItem.ref = doc.ref;
          storeItem.id = doc.id;
          storeItem.index = counter;
          storeItem.name2 = storeItem.lastName
            ? storeItem.firstName + ", " + storeItem.lastName
            : storeItem.firstName;

          this.salesPeople.push(storeItem);
        });
      });
  },
};
</script>
<style></style>
